import ConfirmCodeForm from 'domain/login/confirm-code-form'
import LoginForm from 'domain/login/login-form'
import LoginTokenForm from 'domain/login/token-form'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

export default function LoginPage() {
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />

          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Connexion
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Routes>
              <Route path="/confirm-code" element={<ConfirmCodeForm />} />
              <Route path="/token" element={<LoginTokenForm />}>
                <Route path=":token" />
              </Route>
              <Route path="/" element={<LoginForm />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  )
}
