import React from 'react'
import { useQuery } from 'react-query'
import AccountOverview from 'domain/account/account-details/overview'
import PaymentAccountDetails from 'domain/account/payment-account-details/payment-account-details'
import { fetchAccount } from 'services/api'

export default function AccountPage() {
  const { data } = useQuery('account', fetchAccount)

  if (!data) {
    return null
  }

  const account = data.account

  return (
    <main>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Compte</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div className="mb-4">
              <AccountOverview
                firstName={account.first_name}
                lastName={account.last_name}
                connectionId={account.stripe_account_id}
                email={account.email}
                phoneNumber={account.phone_number}
              />
            </div>

            <div className="mb-4">
              <PaymentAccountDetails
                connectionId={account.stripe_account_id}
                firstName={account.first_name}
                lastName={account.last_name}
                email={account.email}
                phoneNumber={account.phone_number}
                businessType={account.business_type}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
