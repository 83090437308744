import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useLocation, useSearchParams } from 'react-router-dom'
import { getAuth, PhoneAuthProvider, signInWithCredential } from 'firebase/auth'
import AuthCode from 'react-auth-code-input'
import { useNavigate } from 'react-router-dom'

export default function ConfirmCodeForm() {
  const {
    handleSubmit,
    submitting,
    control,
    formState: { errors },
  } = useForm()

  const location = useLocation()
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const verificationId = params.get('v')
  const auth = getAuth()

  const submit = async ({ code }) => {
    try {
      setError(null)
      const credential = PhoneAuthProvider.credential(verificationId, code)
      await signInWithCredential(auth, credential)

      const redirectTo = location.state?.next || '/'
      navigate(redirectTo)
    } catch (error) {
      console.error(error)
      setError(error.message)
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-6">
      <div className="mb-4">
        <label className="block mb-1">
          <div className="text-sm mb-1">
            Entrez le code que vous avez reçu par SMS
          </div>

          <Controller
            control={control}
            name="code"
            render={({ field }) => (
              <AuthCode
                {...field}
                containerClassName="flex -mx-2 my-2"
                inputClassName="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mx-2 text-center"
                allowedCharacters="numeric"
              />
            )}
            rules={{ required: 'Le code est nécessaire' }}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="code"
          render={({ message }) => (
            <div className="text-xs text-red-default">{message}</div>
          )}
        />
      </div>

      {error && <div className="text-red-500 mb-4 text-sm">{error}</div>}

      <div>
        <button
          disabled={submitting}
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Continuer
        </button>
      </div>
    </form>
  )
}
