import React from 'react'
import { useTranslation } from 'react-i18next'

export default function SWNotificationContent({
  onUpdateClick,
  onDismissClick,
}) {
  const { t } = useTranslation('common')

  return (
    <>
      <p className="text-sm font-medium text-gray-900">
        {t('applicationUpdatedTitle')}
      </p>

      <p className="mt-1 text-sm text-gray-500">
        {t('applicationUpdatedContent')}
      </p>

      <div className="mt-3 flex space-x-7">
        <button
          onClick={onUpdateClick}
          type="button"
          className="bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t('applicationUpdatedActionButton')}
        </button>

        <button
          onClick={onDismissClick}
          type="button"
          className="bg-white rounded-md text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t('applicationUpdatedDismissButton')}
        </button>
      </div>
    </>
  )
}
