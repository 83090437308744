import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/outline'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'

export default function ReservationsListItem({
  contactName,
  service,
  city,
  postalCode,
  startsAt,
  displayId,
  to,
}) {
  const mStartsAt = moment(startsAt)
  const startAtDate = mStartsAt.format('ddd DD MMM')
  const startAtTime = mStartsAt.format('HH:mm')

  return (
    <li>
      <Link to={to} className="block px-4 py-4 bg-white hover:bg-gray-50">
        <span className="flex items-center space-x-4">
          <span className="flex-1 flex space-x-2 truncate">
            <CalendarIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />

            <span className="flex flex-col text-gray-500 text-sm truncate">
              <span className="truncate">{displayId}</span>
              <span className="whitespace-normal">{service}</span>
              <span className="truncate">{contactName}</span>

              <span className="truncate">
                {city} ({postalCode})
              </span>

              <time className="truncate" dateTime={mStartsAt.format()}>
                {startAtDate} {startAtTime}
              </time>
            </span>
          </span>

          <ChevronRightIcon
            className="flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </Link>
    </li>
  )
}

export const ReservationsListTableItem = ({
  contactName,
  service,
  city,
  postalCode,
  startsAt,
  displayId,
  to,
}) => {
  const mStartsAt = moment(startsAt)
  const startAtDate = mStartsAt.format('ddd DD MMM')
  const startAtTime = mStartsAt.format('HH:mm')

  return (
    <tr className="bg-white">
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <div className="flex">
          <Link
            to={to}
            className="group inline-flex space-x-2 truncate text-sm"
          >
            <CalendarIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />

            <p className="text-gray-500 truncate group-hover:text-gray-900">
              {displayId}
            </p>
          </Link>
        </div>
      </td>

      <td className="px-6 py-4 text-sm text-gray-500">
        <Link to={to}>{service}</Link>
      </td>

      <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:table-cell">
        <Link to={to}>{contactName}</Link>
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <Link to={to}>
          <time dateTime={mStartsAt.format()}>
            {startAtDate} {startAtTime}
          </time>
        </Link>
      </td>

      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <Link to={to}>
          <span>
            {city} ({postalCode})
          </span>
        </Link>
      </td>
    </tr>
  )
}
