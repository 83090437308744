import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { fetchReservationsList } from 'services/api'
import ReservationsTableHead from './head'
import { ReservationsListTableItem } from './item'

const useReservationsList = () => {
  const page = 1
  const perPage = 5
  const now = moment().toISOString()
  const endsAtParam = `>${now}`
  const sortParam = '+starts_at,+external_id'

  const { data, isLoading } = useQuery(
    ['reservations', { perPage, sort: sortParam, page }],
    () =>
      fetchReservationsList({
        sort: sortParam,
        perPage,
        page,
        endsAt: endsAtParam,
        status: '!=pending',
      })
  )

  return {
    reservations: data?.reservations || [],
    isLoading,
  }
}

export default function ReservationsTable() {
  const { t } = useTranslation('dashboard')
  const { reservations, isLoading } = useReservationsList()

  return (
    <div className="hidden sm:block">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col mt-2">
          <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <ReservationsTableHead />

              <tbody className="bg-white divide-y divide-gray-200">
                {reservations.map((reservation) => (
                  <ReservationsListTableItem
                    key={reservation.id}
                    id={reservation.id}
                    contactName={[
                      reservation.contact.first_name,
                      reservation.contact.last_name,
                    ].join(' ')}
                    service={[
                      reservation.service_category_name,
                      reservation.service_group_name,
                      reservation.service_name,
                    ].join(' / ')}
                    city={reservation.contact.city}
                    postalCode={reservation.contact.postcode}
                    startsAt={reservation.starts_at}
                    endsAt={reservation.ends_at}
                    displayId={reservation.external_id}
                    to={`/reservations/${reservation.id}`}
                  />
                ))}

                {!isLoading && reservations.length === 0 && (
                  <tr className="bg-white">
                    <td
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center"
                      colSpan={5}
                    >
                      <div>{t('noUpcomingReservation')}</div>
                      <div>
                        {' '}
                        <Link
                          className="font-medium text-cyan-700 hover:text-cyan-900"
                          to="/reservations"
                        >
                          {t('seeAllReservations')}
                        </Link>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {reservations.length > 0 && (
              <div className="bg-white px-4 py-3 border-t border-gray-200 sm:px-6 min-w-full">
                <div className="text-center text-sm">
                  <Link
                    className="font-medium text-cyan-700 hover:text-cyan-900"
                    to="/reservations"
                  >
                    {t('seeAllReservations')}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
