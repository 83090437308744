import { useTranslation } from 'react-i18next'

export default function ReservationsListHead() {
  const { t } = useTranslation(['reservations-list-head'])

  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
        >
          #
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell"
        >
          {t('Date')}
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden xl:table-cell"
        >
          {t('Client')}
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {t('Treatment')}
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden xl:table-cell"
        >
          {t('City')}
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell"
        >
          {t('Status')}
        </th>
      </tr>
    </thead>
  )
}
