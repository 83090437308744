import { getAuth } from 'firebase/auth'
import urlJoin from 'url-join'
import qs from 'query-string'
import { parseResponse } from './helpers'

const API_URL = process.env.REACT_APP_API_URL

const route = (apiPath) => urlJoin(API_URL, 'v2', apiPath)

const ffetch = async (apiPath, options = {}) => {
  try {
    const auth = getAuth()
    const accessToken =
      options.accessToken || (await auth.currentUser.getIdToken())

    options.headers = {
      ...options.headers,
      'content-type': 'application/json',
    }

    if (accessToken) {
      options.headers.Authorization = `Bearer ${accessToken}`
    }

    const url = route(apiPath)
    const response = await fetch(url, options)
    const responseData = await parseResponse(response)

    return responseData
  } catch (error) {
    throw error
  }
}

const API = {
  fetch: ffetch,
}

export default API

export const fetchAccount = () => ffetch('/account')
export const fetchConnectedAccount = ({ includeBalance = false }) => {
  const url = qs.stringifyUrl(
    {
      url: '/connected_account',
      query: {
        include_balance: includeBalance ? 'true' : null,
      },
    },
    {
      skipNull: true,
    }
  )

  return ffetch(url)
}

export const fetchReservationsList = ({
  organizationId,
  perPage,
  sort,
  page,
  endsAt,
  status,
  externalId,
}) => {
  const filterObject = { status, external_id: externalId, ends_at: endsAt }
  const sanitizedFilterObject = Object.fromEntries(
    Object.entries(filterObject).filter(([_, v]) => Boolean(v))
  )
  const filter = Object.entries(sanitizedFilterObject)
    .map(([k, v]) => `${k}${v}`)
    .join(',')

  const url = qs.stringifyUrl({
    url: `${
      organizationId ? `organization/${organizationId}` : ''
    }/reservations`,
    query: {
      filter,
      per_page: perPage,
      page,
      sort,
    },
  })

  return ffetch(url)
}

export const LogInConnectedAccount = () =>
  ffetch(`/connected_account/login`, {
    method: 'POST',
  })

export const fetchReservation = ({ reservationId, organizationId }) =>
  ffetch(
    `${
      organizationId ? `organization/${organizationId}` : ''
    }/reservations/${reservationId}`
  )

export const updateAvailabilities = (availabilties) =>
  ffetch(`/availabilties`, {
    method: 'POST',
    body: JSON.stringify(availabilties),
  })
