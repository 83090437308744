import React, { Fragment, useCallback, useState } from 'react'
import { Routes, Route, NavLink } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import {
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  XIcon,
} from '@heroicons/react/outline'

import AccountPage from './account'
import CreateConnectedAccount from 'domain/account/connected-account/create'
import LogInConnectedAccount from 'domain/account/connected-account/login'
import ProfileDropdown from 'domain/layout/profile-dropdown'
import Sidebar from 'domain/layout/sidebar'
import classNames from 'classnames'
import ReservationsListPage from './reservations-list'
import ReservationDetailsPage from './reservation-details'
import DashboardPage from './dashboard'
import SettingsPage from './settings'
import CalendarSettingsPage from './settings/calendar'
import CalendarSyncPage from './settings/calendar-sync'
import { fetchAccount } from 'services/api'
import { useQuery } from 'react-query'
import moment from 'moment-timezone'

const navigation = [
  { name: 'Dashboard', to: '/', icon: HomeIcon, end: true },
  {
    name: 'Réservations',
    to: '/reservations',
    icon: InboxIcon,
  },
]

export default function MainPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const handleSidebarLinkClick = useCallback(() => setSidebarOpen(false), [])
  useQuery(
    'account',
    fetchAccount, 
    {
      onSuccess: ({ account }) => moment.tz.setDefault(account.timezone)
    },
  )

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-indigo-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-logo-indigo-300-mark-white-text.svg"
                    alt="Workflow"
                  />
                </div>

                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <NavLink
                        onClick={handleSidebarLinkClick}
                        to={item.to}
                        key={item.name}
                        end={item.end}
                        className={({ isActive }) =>
                          classNames(
                            {
                              'bg-indigo-800 text-white': isActive,
                              'text-indigo-100 hover:bg-indigo-600': !isActive,
                            },
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )
                        }
                      >
                        <item.icon
                          className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>

            <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          <Sidebar />
        </div>

        <div className="lg:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form> */}
              </div>

              <div className="ml-4 flex items-center md:ml-6">
                {/* <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                <ProfileDropdown />
              </div>
            </div>
          </div>

          <Routes>
            <Route path="/dashboard" element={<DashboardPage />} />

            <Route path="/settings" element={<SettingsPage />}>
              <Route
                path="calendar/sync"
                exact
                element={<CalendarSyncPage />}
              />
              <Route path="calendar" element={<CalendarSettingsPage />} />
            </Route>

            <Route
              path="/account/connect/login"
              element={<LogInConnectedAccount />}
            />

            <Route
              path="/account/connect"
              exact
              element={<CreateConnectedAccount />}
            />

            <Route path="/account" element={<AccountPage />} />

            <Route path="/reservations">
              <Route index element={<ReservationsListPage />} />
              <Route
                path="past"
                element={<ReservationsListPage startsAt="past" />}
              />
              <Route
                path=":reservationId"
                element={<ReservationDetailsPage />}
              />
            </Route>

            <Route path="/org/:organizationId">
              <Route path="reservations">
                <Route index element={<ReservationsListPage />} />
                <Route
                  path="past"
                  element={<ReservationsListPage startsAt="past" />}
                />
                <Route
                  path=":reservationId"
                  element={<ReservationDetailsPage />}
                />
              </Route>
            </Route>

            <Route path="/" element={<DashboardPage />} />
          </Routes>
        </div>
      </div>
    </>
  )
}
