import React from 'react'
import Balance from 'domain/dashboard/balance/balance'
import ReservationsList from 'domain/dashboard/reservations/list'
import { useTranslation } from 'react-i18next'
import ReservationsTable from 'domain/dashboard/reservations/table'

export default function DashboardPage() {
  const { t } = useTranslation('dashboard')

  return (
    <div className="mt-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg leading-6 font-medium text-gray-900">
          {t('overview')}
        </h2>

        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <Balance />
        </div>
      </div>

      <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
        {t('nextReservations')}
      </h2>

      <ReservationsList />
      <ReservationsTable />
    </div>
  )
}
