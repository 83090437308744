import React from 'react'
import { useQuery } from 'react-query'
import { fetchReservationsList } from 'services/api'
import { useParams, useSearchParams } from 'react-router-dom'

import ReservationsListItem from './item'
import ReservationsListHead from './head'
import ReservationsListPagination from './pagination'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const useReservationsList = (startsAt) => {
  const [searchParams] = useSearchParams()
  const { organizationId } = useParams()

  const page = searchParams.get('page') || 1
  const perPage = searchParams.get('per_page') || 10

  const now = moment().toISOString()
  const endsAtParam = [startsAt === 'past' ? '<' : '>', now].join('')
  const sortParam = [
    startsAt === 'past' ? '-starts_at' : '+starts_at',
    '+external_id',
  ].join(',')

  const { data, isLoading } = useQuery(
    ['reservations', { perPage, sort: sortParam, page }],
    () =>
      fetchReservationsList({
        organizationId,
        sort: sortParam,
        perPage,
        page,
        endsAt: endsAtParam,
        status: '!=pending',
      }),
    {
      keepPreviousData: true,
    }
  )

  return {
    reservations: data?.reservations || [],
    isLoading,
    pagination: data?.meta.pagination,
    organizationId,
  }
}

export default function ReservationsList({ startsAt }) {
  const { reservations, isLoading, pagination, organizationId } =
    useReservationsList(startsAt)
  const { t } = useTranslation([
    'reservations-list',
    'reservations-list-head',
    'reservations-list-item',
    'reservation-status',
  ])

  return (
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <ReservationsListHead />

              <tbody className="bg-white">
                {isLoading && (
                  <tr>
                    <td
                      component="th"
                      align="center"
                      colSpan={7}
                      className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {t('loadingReservations')}
                    </td>
                  </tr>
                )}

                {!isLoading && reservations.length === 0 && (
                  <tr>
                    <td
                      component="th"
                      align="center"
                      colSpan={7}
                      className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {t('noReservationsFound')}
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  reservations.map((reservation) => (
                    <ReservationsListItem
                      key={reservation.id}
                      id={reservation.id}
                      contactName={[
                        reservation.contact.first_name,
                        reservation.contact.last_name,
                      ].join(' ')}
                      service={[
                        reservation.service_category_name,
                        reservation.service_group_name,
                        reservation.service_name,
                      ].join(' / ')}
                      city={reservation.contact.city}
                      postalCode={reservation.contact.postcode}
                      startsAt={reservation.starts_at}
                      endsAt={reservation.ends_at}
                      status={reservation.status}
                      displayId={reservation.external_id}
                      to={`../${
                        organizationId
                          ? reservation.external_id
                          : reservation.id
                      }`}
                    />
                  ))}
              </tbody>
            </table>

            {pagination && pagination.total_pages > 0 && (
              <ReservationsListPagination
                count={reservations.length}
                totalCount={pagination.total_count}
                currentPage={pagination.current_page}
                totalPages={pagination.total_pages}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
