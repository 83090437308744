import Breadcrumb from 'components/breadcrumb'
import useReservation from './use-reservation'

export default function ReservationDetailsHeader() {
  const { reservation } = useReservation()

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-4">
        <Breadcrumb
          links={[
            {
              label: 'Réservations',
              to: '..',
            },
            reservation && {
              label: reservation.service_name,
            },
          ]}
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          {reservation && reservation.service_name}
        </h1>
      </div>
    </>
  )
}
