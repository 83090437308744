import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { google, outlook, office365, yahoo, ics } from 'calendar-link'

export default function AddToCalendarDropdown({
  customerFirstName,
  customerLastName,
  serviceName,
  displayId,
  contactName,
  address,
  address2,
  postalCode,
  city,
  formattedPhoneNumber,
  startsAt,
  endsAt,
}) {
  const { t } = useTranslation(['reservation-details'])
  const fullAddress = [address, postalCode, city].join(' ')
  const event = {
    title: `RDV SIMONE avec ${customerFirstName} ${customerLastName} pour ${serviceName}`,
    start: moment(startsAt).format(),
    end: moment(endsAt).format(),
    description: `Id : ${displayId}
Client : ${customerFirstName} ${customerLastName}
Soin : ${serviceName}\n
Nom du contact : ${contactName}
Addresse : ${[address, address2].filter(Boolean).join('\n')}
${postalCode} ${city}
Numéro de téléphone : ${formattedPhoneNumber}
`,
    location: fullAddress,
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          {t('addToMyCalendar')}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right bottom-11 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href={google(event)}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Google
                </a>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <a
                  href={outlook(event)}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Outlook
                </a>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <a
                  href={office365(event)}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Office 365
                </a>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <a
                  href={yahoo(event)}
                  target="_blank"
                  rel="noreferrer"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Yahoo
                </a>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <a
                  href={ics(event)}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  Fichier .ics
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
