import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import md5 from 'md5'
import { Fragment, useCallback } from 'react'
import { useQuery } from 'react-query'
import API from 'services/api'
import { getAuth, signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

export default function ProfileDropdown() {
  const navigate = useNavigate()

  const { data } = useQuery('account', () => API.fetch('/account'))

  const handleLogout = useCallback(async () => {
    await signOut(getAuth())
    navigate('/login')
  }, [navigate])

  const account = data?.account

  if (!account) {
    return null
  }

  const md5Email = md5(account.email)

  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="sr-only">Open user menu</span>
          <img
            className="h-8 w-8 rounded-full"
            src={`https://www.gravatar.com/avatar/${md5Email}`}
            alt={account.email}
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={handleLogout}
                className={classNames(
                  {
                    'bg-gray-100': active,
                  },
                  'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                )}
              >
                Se déconnecter
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
