import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { useNavigate, useParams } from 'react-router-dom'

export default function LoginTokenForm() {
  const { token } = useParams()

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm({ defaultValues: { token } })

  const buttonElement = useRef(null)
  const navigate = useNavigate()
  const [error, setError] = useState(null)

  const submit = async ({ token }) => {
    try {
      setError(null)

      await signInWithCustomToken(getAuth(), token)
      navigate('/')
    } catch (error) {
      console.error(error)
      setError(error.message)
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-6">
      <div>
        <label
          htmlFor="token"
          className="block text-sm font-medium text-gray-700"
        >
          Token
        </label>

        <div className="mt-1 relative">
          <textarea
            {...register('token', { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>
      </div>

      {error && <div className="text-red-500 mb-4 text-sm">{error}</div>}

      <div>
        <button
          disabled={isSubmitting}
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          ref={buttonElement}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}
