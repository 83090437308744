import CalendarScheduleForm from 'domain/calendar/schedule/form'
import CalendarSyncForm from 'domain/calendar/sync/form'

export default function CalendarSettingsPage() {
  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <CalendarSyncForm />
      <CalendarScheduleForm />
    </div>
  )
}
