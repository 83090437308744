import DescriptionListField from 'components/description-list/description-list-field'
import useReservation from './use-reservation'
import parsePhoneNumber from 'libphonenumber-js'
import ReservationSummary from './summary'
import ReservationContact from './contact'

export default function ReservationDetails() {
  const { reservation } = useReservation()

  if (!reservation) {
    return null
  }

  const formattedPrice = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: reservation.fees.currency_iso,
  }).format(reservation.fees.cents / 100)
  const formattedPhoneNumber = parsePhoneNumber(
    reservation.contact.phone_number
  ).formatNational()
  const contactName = [
    reservation.contact.first_name,
    reservation.contact.last_name,
  ].join(' ')

  return (
    <>
      <ReservationSummary
        id={reservation.id}
        displayId={reservation.external_id}
        customerFirstName={reservation.customer.first_name}
        customerLastName={reservation.customer.last_name}
        serviceName={[
          reservation.service_category_name,
          reservation.service_group_name,
          reservation.service.name,
        ].join(' / ')}
        startsAt={reservation.starts_at}
        endsAt={reservation.ends_at}
        status={reservation.status}
        contactName={contactName}
        address={reservation.contact.address}
        address2={reservation.contact.address2}
        postalCode={reservation.contact.postcode}
        city={reservation.contact.city}
        formattedPhoneNumber={formattedPhoneNumber}
      />

      <ReservationContact
        contactName={contactName}
        address={reservation.contact.address}
        address2={reservation.contact.address2}
        postalCode={reservation.contact.postcode}
        city={reservation.contact.city}
        phoneNumber={reservation.contact.phone_number}
        formattedPhoneNumber={formattedPhoneNumber}
      />

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Facturation
          </h3>
          {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Prix de la prestation et facture.
          </p> */}
        </div>

        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <DescriptionListField label="Chiffre d'affaires TTC">
              {formattedPrice}
            </DescriptionListField>
          </dl>
        </div>
      </div>
    </>
  )
}
