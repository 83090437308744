import classNames from 'classnames'

const CLASS_NAMES = {
  gray: 'bg-gray-100 text-gray-800',
  indigo: 'bg-indigo-100 text-indigo-800',
  green: 'bg-green-100 text-green-800',
  red: 'bg-red-100 text-red-800',
}

export default function Badge({ color, children }) {
  return (
    <>
      <span
        className={classNames(
          'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium',
          CLASS_NAMES[color] || CLASS_NAMES.gray
        )}
      >
        {children}
      </span>
    </>
  )
}
