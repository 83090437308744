import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReservationStatusBadge from '../status-badge'

export default function ReservationsListItem({
  id,
  contactName,
  service,
  city,
  postalCode,
  startsAt,
  status,
  displayId,
  endsAt,
  to,
}) {
  const mStartsAt = moment(startsAt)
  const mEndsAt = moment(endsAt)
  const startAtDate = mStartsAt.format('ddd DD MMM')
  const startAtTime = mStartsAt.format('HH:mm')
  const endsAtTime = mEndsAt.format('HH:mm')
  const { t } = useTranslation(['reservations-list-item'])

  return (
    <tr className="even:bg-gray-50">
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <Link
          to={to}
          relative="path"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          {displayId}
        </Link>
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell">
        {startAtDate} {startAtTime}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden xl:table-cell">
        {contactName}
      </td>

      <td className="px-3 py-4 text-sm text-gray-900 xl:text-gray-500">
        <Link to={to}>
          {service}

          <dl className="font-normal xl:hidden">
            <dt className="sr-only sm:hidden">{t('Client')}</dt>
            <dd className="mt-1 truncate text-gray-500 xl:hidden">
              {contactName}
            </dd>
            <dt className="sr-only sm:hidden">{t('City')}</dt>
            <dd className="mt-1 truncate text-gray-500 xl:hidden">
              {city} ({postalCode})
            </dd>
            <dt className="sr-only sm:hidden">{t('Status')}</dt>
            <dd className="mt-1 truncate text-gray-500 lg:hidden">
              {startAtDate} @ {startAtTime} - {endsAtTime}
            </dd>
            <dt className="sr-only sm:hidden">{t('Status')}</dt>
            <dd className="mt-1 truncate lg:hidden">
              <ReservationStatusBadge status={status} />
            </dd>
          </dl>
        </Link>
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden xl:table-cell">
        {city} ({postalCode})
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm hidden lg:table-cell">
        <ReservationStatusBadge status={status} />
      </td>
    </tr>
  )
}
