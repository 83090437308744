import React, { useCallback } from 'react'
import api from 'services/api'
import { useQuery } from 'react-query'
import AccountDetailsEmptyPayment from './empty-payment-account'
import DescriptionField from 'components/description-list/description-field'
import moment from 'moment-timezone'

export default function PaymentAccountDetails({
  email,
  phoneNumber,
  firstName,
  lastName,
  connectionId,
  businessType,
}) {
  const { data } = useQuery(
    'connected_account',
    () => api.fetch('/connected_account'),
    {
      enabled: !!connectionId,
    }
  )
  const connectedAccount = data && data.connected_account

  const handleOpenConnectionClick = useCallback(async () => {
    window.open('/account/connect/login', '_blank')
  }, [])

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Paiment</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Détails du compte de paiement
        </p>
      </div>

      {!connectionId && (
        <AccountDetailsEmptyPayment
          email={email}
          phoneNumber={phoneNumber}
          firstName={firstName}
          lastName={lastName}
          businessType={businessType}
        />
      )}

      {connectedAccount && (
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <DescriptionField label="ID">
              <span className="flex-grow">{connectedAccount.id}</span>
              <span className="ml-4 flex-shrink-0">
                <button
                  onClick={handleOpenConnectionClick}
                  type="button"
                  className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Voir mon compte
                </button>
              </span>
            </DescriptionField>

            <DescriptionField label="Informations soumises">
              {connectedAccount.details_submitted ? 'Oui' : 'Non'}
            </DescriptionField>

            <DescriptionField label="Virements activés">
              {connectedAccount.payouts_enabled ? 'Oui' : 'Non'}
            </DescriptionField>

            <DescriptionField label="Créé le">
              {moment(connectedAccount.created_at).format(
                '[le] DD/MM/YYYY [à] HH:mm'
              )}
            </DescriptionField>
          </dl>
        </div>
      )}
    </div>
  )
}
