import React, { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import api from 'services/api'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function CreateAccountConnect() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [params] = useSearchParams()

  const code = params.get('code')

  const {
    mutate: createConnectedAccount,
    error,
    isSuccess,
  } = useMutation(
    () =>
      api.fetch(`/connected_account`, {
        method: 'POST',
        body: JSON.stringify({
          connected_account: {
            code,
          },
        }),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('account')
      },
    }
  )

  useEffect(() => {
    ;(async function create() {
      await createConnectedAccount()
    })()
  }, [createConnectedAccount, navigate])

  if (error) {
    return (
      <div className="py-10 text-center">
        Une erreur s'est produite: {error.message}
      </div>
    )
  }

  if (isSuccess) {
    navigate('/account')
  }

  return (
    <div className="py-10 text-center">
      Validation du compte partenaire en cours
    </div>
  )
}
