import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { useCallback } from 'react'

export default function ReservationsListPagination({
  count,
  totalCount,
  currentPage,
  totalPages,
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  const handlePageChange = useCallback(
    (event) => {
      searchParams.set('page', event.currentTarget.dataset.page)
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const handleSelectedPageChange = useCallback(
    (event) => {
      searchParams.set('page', event.currentTarget.value)
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const options = new Array(totalPages).fill('').map((_, index) => ({
    label: `Page ${index + 1}`,
    value: index + 1,
  }))

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          disabled={currentPage <= 1}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={handlePageChange}
          data-page={currentPage - 1}
        >
          Préc.
        </button>

        <button
          disabled={currentPage >= totalPages}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={handlePageChange}
          data-page={currentPage + 1}
        >
          Suiv.
        </button>
      </div>

      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Affichage de la page{' '}
            <span className="font-medium">{currentPage}</span> sur{' '}
            <span className="font-medium">{totalPages}</span> (total de{' '}
            <span className="font-medium">{totalCount}</span> réservations)
          </p>
        </div>

        {totalPages > 1 &&
          (totalPages < 10 ? (
            <div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <button
                  disabled={currentPage <= 1}
                  onClick={handlePageChange}
                  data-page={currentPage - 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>

                {new Array(totalPages).fill('').map((_, index) => (
                  <button
                    key={index}
                    onClick={handlePageChange}
                    className={classNames(
                      'relative inline-flex items-center px-4 py-2 border text-sm font-medium',
                      {
                        'z-10 bg-indigo-50 border-indigo-500 text-indigo-600':
                          currentPage === index + 1,
                        'bg-white border-gray-300 text-gray-500 hover:bg-gray-50':
                          currentPage !== index + 1,
                      }
                    )}
                    data-page={index + 1}
                  >
                    {index + 1}
                  </button>
                ))}

                <button
                  disabled={currentPage >= totalPages}
                  onClick={handlePageChange}
                  data-page={currentPage + 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Suiv.</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          ) : (
            <div>
              <select
                name="location"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                selected={currentPage}
                onChange={handleSelectedPageChange}
              >
                {options.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          ))}
      </div>
    </div>
  )
}
