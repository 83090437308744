import classNames from 'classnames'

export default function Link({ as: Component, className, ...props }) {
  return (
    <Component
      {...props}
      className={classNames(
        className,
        'font-medium text-indigo-600 hover:text-indigo-500'
      )}
    />
  )
}
