import DescriptionListField from 'components/description-list/description-list-field'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import ReservationStatusBadge from '../status-badge'
import AddToCalendarDropdown from './add-to-calendar-dropdown'

export default function ReservationSummary({
  displayId,
  customerFirstName,
  customerLastName,
  serviceName,
  startsAt,
  endsAt,
  status,
  contactName,
  address,
  address2,
  postalCode,
  city,
  formattedPhoneNumber,
}) {
  const { t } = useTranslation([
    'reservation-details',
    'reservations-list-head',
    'reservations-list-item',
    'reservation-status',
  ])
  const formattedStartsAt = moment(startsAt).format('DD/MM/YYYY HH:mm')
  const formattedEndsAt = moment(endsAt).format('HH:mm')

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t('Summary')}
        </h3>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <DescriptionListField label={t('Id')}>
            {displayId}
          </DescriptionListField>

          <DescriptionListField label={t('Client')}>
            {customerFirstName} {customerLastName}
          </DescriptionListField>

          <DescriptionListField label={t('Treatment')}>
            {serviceName}
          </DescriptionListField>

          <DescriptionListField label={t('Date')}>
            {formattedStartsAt} - {formattedEndsAt}
            <div className="mt-2">
              <AddToCalendarDropdown
                displayId={displayId}
                startsAt={startsAt}
                endsAt={endsAt}
                customerFirstName={customerFirstName}
                customerLastName={customerLastName}
                serviceName={serviceName}
                contactName={contactName}
                address={address}
                address2={address2}
                postalCode={postalCode}
                city={city}
                formattedPhoneNumber={formattedPhoneNumber}
              />
            </div>
          </DescriptionListField>

          <DescriptionListField label={t('Status')}>
            <ReservationStatusBadge status={status} />
          </DescriptionListField>
        </dl>
      </div>
    </div>
  )
}
