import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { fetchReservation } from 'services/api'

const useReservation = () => {
  const { reservationId, organizationId } = useParams()

  const { data, isLoading } = useQuery(
    ['reservations', reservationId],
    async () => fetchReservation({ reservationId, organizationId })
  )

  return {
    reservation: data?.reservation,
    isLoading,
  }
}

export default useReservation
