import { HomeIcon, InboxIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

const navigation = [
  { name: 'Dashboard', to: '/', icon: HomeIcon, end: true },
  {
    name: 'Réservations',
    to: '/reservations',
    icon: InboxIcon,
  },
]

export default function Sidebar() {
  return (
    <div className="flex flex-col flex-grow pt-5 bg-indigo-700 overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <img
          className="h-8 w-auto"
          src="https://tailwindui.com/img/logos/workflow-logo-indigo-300-mark-white-text.svg"
          alt="Workflow"
        />
      </div>

      <div className="mt-5 flex-1 flex flex-col">
        <nav className="flex-1 px-2 pb-4 space-y-1">
          {navigation.map((item) => (
            <NavLink
              end={item.end}
              key={item.name}
              to={item.to}
              className={({ isActive }) =>
                classNames(
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  {
                    'bg-indigo-800 text-white': isActive,
                    'text-indigo-100 hover:bg-indigo-600': !isActive,
                  }
                )
              }
            >
              <item.icon
                className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
                aria-hidden="true"
              />
              {item.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  )
}
