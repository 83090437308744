import { useTranslation } from 'react-i18next'
import qs from 'query-string'

export default function CalendarSyncForm() {
  const { t } = useTranslation('calendar-settings')

  const syncUrl = qs.stringifyUrl({
    url: 'https://app-de.cronofy.com/oauth/authorize',
    query: {
      response_type: 'code',
      client_id: process.env.REACT_APP_CRONOFY_CLIENT_ID,
      redirect_uri: `${window.location.origin}/settings/calendar/sync`,
      scope: encodeURIComponent('read_write'),
      locale: 'fr',
    },
  })

  return (
    <div className="shadow sm:rounded-md sm:overflow-hidden">
      <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('syncTitle')}
          </h3>

          <p className="mt-1 text-sm text-gray-500">{t('syncDesc')}</p>
        </div>

        <div className="mt-1 text-sm text-gray-500">{t('syncExplain')}</div>

        <div>
          <a
            href={syncUrl}
            className="inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mx-1"
          >
            {t('syncWithCalendar')}
          </a>
        </div>
      </div>
    </div>
  )
}
