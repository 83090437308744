import { ScaleIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { fetchAccount, fetchConnectedAccount } from 'services/api'
import MissingAccount from './missing-account'

const useBalance = () => {
  const { data, isLoading } = useQuery('account', fetchAccount)
  const { data: connectedAccountData, isLoading: connectedAccountIsLoading } =
    useQuery(
      ['connected_account', { include_balance: true }],
      () => fetchConnectedAccount({ includeBalance: true }),
      {
        enabled: !!data?.account.stripe_account_id,
      }
    )

  return {
    isLoading: isLoading || connectedAccountIsLoading,
    account: data?.account,
    connectedAccount: connectedAccountData?.connected_account,
  }
}

export default function Balance() {
  const { isLoading, account, connectedAccount } = useBalance()
  const { t } = useTranslation('dashboard')

  if (isLoading) {
    return null
  }

  if (!account.stripe_account_id) {
    return (
      <MissingAccount
        firstName={account.first_name}
        lastName={account.last_name}
        email={account.email}
        phoneNumber={account.phone_number}
        businessType={account.business_type}
      />
    )
  }

  const balance = connectedAccount.balance.available.reduce(
    (acc, balance) => acc + balance.amount,
    0
  )

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <ScaleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          </div>

          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">
                Compte
              </dt>

              <dd>
                <div className="text-lg font-medium text-gray-900">
                  {Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'eur',
                  }).format(balance / 100)}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 px-5 py-3">
        <div className="text-sm">
          <Link
            to="/account/connect/login"
            className="font-medium text-cyan-700 hover:text-cyan-900"
          >
            {t('seeTransactions')}
          </Link>
        </div>
      </div>
    </div>
  )
}
