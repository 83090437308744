import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

export default function Breadcrumb({ links }) {
  const lastLink = links.length > 1 ? links[links.length - 2] : null

  return (
    <>
      {lastLink && (
        <nav className="sm:hidden" aria-label="Back">
          <Link
            to={lastLink.to}
            className="flex items-center text-sm font-medium text-gray-400 hover:text-gray-200"
          >
            <ChevronLeftIcon
              className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
            Back
          </Link>
        </nav>
      )}

      <nav className="hidden sm:flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div className="flex">
              <Link
                to="/"
                className="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Home
              </Link>
            </div>
          </li>

          {links.filter(Boolean).map((link, index) => (
            <li key={link.label}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {link.to ? (
                  <Link
                    to={link.to}
                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    aria-current={
                      index === links.length - 1 ? 'page' : undefined
                    }
                  >
                    {link.label}
                  </Link>
                ) : (
                  <span
                    className="ml-4 text-sm font-medium text-gray-500"
                    aria-current={
                      index === links.length - 1 ? 'page' : undefined
                    }
                  >
                    {link.label}
                  </span>
                )}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </>
  )
}
