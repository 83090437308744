import Badge from 'components/badge'
import { useTranslation } from 'react-i18next'

const STATUS_BADGE_COLORS = {
  pending: 'gray',
  scheduled: 'indigo',
  complete: 'green',
  cancelled: 'red',
}

export default function ReservationStatusBadge({ status }) {
  const { t } = useTranslation('reservation-status')
  const badgeStatusColor = STATUS_BADGE_COLORS[status] || 'gray'

  return <Badge color={badgeStatusColor}>{t(status)}</Badge>
}
