import ReservationDetails from 'domain/reservations/details/details'
import ReservationDetailsHeader from 'domain/reservations/details/header'

export default function ReservationDetailsPage() {
  return (
    <main>
      <div className="py-6">
        <ReservationDetailsHeader />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Content goes here */}

          <div className="mt-8">
            <ReservationDetails />
          </div>
        </div>
      </div>
    </main>
  )
}
