import { CalendarIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { NavLink, Outlet } from 'react-router-dom'

export default function SettingsPage() {
  const navigation = [{ name: 'Calendar', to: 'calendar', icon: CalendarIcon }]

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5 lg:p-4">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <nav className="space-y-1">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.to}
              className={({ isActive }) =>
                classNames(
                  {
                    'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white':
                      isActive,
                    'text-gray-900 hover:text-gray-900 hover:bg-gray-50':
                      !isActive,
                  },
                  'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                )
              }
            >
              {({ isActive }) => (
                <>
                  <item.icon
                    className={classNames(
                      {
                        'text-indigo-500 group-hover:text-indigo-500': isActive,
                        'text-gray-400 group-hover:text-gray-500': !isActive,
                      },
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                </>
              )}
            </NavLink>
          ))}
        </nav>
      </aside>

      <Outlet />
    </div>
  )
}
