import React, { useEffect, useState } from 'react'
import api from 'services/api'

export default function LogInConnectedAccount() {
  const [error, setError] = useState(null)

  useEffect(() => {
    ;(async function create() {
      try {
        const { connected_account: connectedAccount } = await api.fetch(
          `/connected_account/login`,
          {
            method: 'POST',
          }
        )

        window.location = connectedAccount.login_link
      } catch (error) {
        setError(error.message)
      }
    })()
  }, [])

  if (error) {
    return (
      <div className="py-10 text-center">
        Une erreur s'est produite: {error}
      </div>
    )
  }

  return null
}
