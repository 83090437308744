import Breadcrumb from 'components/breadcrumb'
import ReservationsPageHeader from 'domain/reservations/header'
import ReservationsList from 'domain/reservations/list/list'

export default function ReservationsListPage({ startsAt }) {
  return (
    <main>
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mb-4">
          <Breadcrumb
            links={[
              {
                label: 'Réservations',
                to: '/reservations',
              },
            ]}
          />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Réservations</h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* Content goes here */}
          <ReservationsPageHeader />

          <div className="mt-8">
            <ReservationsList startsAt={startsAt} />
          </div>
        </div>
      </div>
    </main>
  )
}
