import { useTranslation } from 'react-i18next'

export default function ReservationsTableHead() {
  const { t } = useTranslation('dashboard')

  return (
    <thead>
      <tr>
        <th
          className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          {t('DisplayId')}
        </th>
        <th
          className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          {t('Service')}
        </th>
        <th
          className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block"
          scope="col"
        >
          {t('Client')}
        </th>
        <th
          className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          {t('Date')}
        </th>
        <th
          className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          scope="col"
        >
          {t('City')}
        </th>
      </tr>
    </thead>
  )
}
