import classNames from 'classnames'
import { useCallback } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

const tabs = [
  { name: 'À venir', to: '..', current: true },
  { name: 'Passées', to: '../past', current: true },
]

export default function ReservationsPageHeader() {
  const location = useLocation()
  const navigate = useNavigate()

  const handleTabSelectChange = useCallback(
    (e) => navigate(e.currentTarget.value, { replace: true }),
    [navigate]
  )

  return (
    <div className="pb-5 border-b border-gray-200 sm:pb-0">
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>

          <select
            id="current-tab"
            name="current-tab"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            onChange={handleTabSelectChange}
            value={location.pathname}
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.to}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>

        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                end
                key={tab.name}
                to={tab.to}
                className={({ isActive }) =>
                  classNames(
                    {
                      'border-indigo-500 text-indigo-600': isActive,
                      'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                        isActive,
                    },
                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                  )
                }
              >
                {({ isActive }) => (
                  <span aria-current={isActive ? 'page' : undefined}>
                    {tab.name}
                  </span>
                )}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
