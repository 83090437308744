import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input/react-hook-form-input'
import { ErrorMessage } from '@hookform/error-message'
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

export default function LoginForm() {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm()

  const buttonElement = useRef(null)
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const location = useLocation()

  const auth = getAuth()
  auth.languageCode = 'fr'

  useEffect(() => {
    if (buttonElement.current) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        buttonElement.current,
        {
          size: 'invisible',
        },
        auth
      )
    }
  }, [auth])

  const submit = async ({ phoneNumber }) => {
    try {
      const appVerifier = window.recaptchaVerifier

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        appVerifier
      )

      navigate(`/login/confirm-code?v=${confirmationResult.verificationId}`, {
        state: location.state,
      })
    } catch (error) {
      console.error(error)
      setError(error.message)

      window.recaptchaVerifier
        .render()
        .then((widgetId) => window.grecaptcha.reset(widgetId))
    }
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="space-y-6">
      <div>
        <label
          htmlFor="phoneNumber"
          className="block text-sm font-medium text-gray-700"
        >
          Numéro de téléphone
        </label>

        <div className="mt-1 relative">
          <PhoneInput
            id="phoneNumber"
            name="phoneNumber"
            control={control}
            rules={{
              required: 'Votre numéro de téléphone est obligatoire',
            }}
            defaultCountry="FR"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="06 12 34 56 78"
          />

          <ErrorMessage
            errors={errors}
            name="phoneNumber"
            render={() => (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          />
        </div>
      </div>

      {error && <div className="text-red-500 mb-4 text-sm">{error}</div>}

      <div>
        <button
          disabled={isSubmitting}
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          ref={buttonElement}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}
