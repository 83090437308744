import React, { Suspense, useCallback, useState } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import MainPage from './pages/app'
import LoggedOutPage from 'pages/logged-out'
import { QueryClient, QueryClientProvider } from 'react-query'
import LoginPage from 'pages/login'
import { getAuth } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import Notification from 'components/notification'
import SWNotificationContent from 'components/sw-notification-content'
import useEventListener from 'hooks/use-event-listener'

const RequireAuth = ({ children }) => {
  const location = useLocation()
  const auth = getAuth()
  const [user, loading] = useAuthState(auth)

  if (loading) {
    return null
  }

  if (!user) {
    return <Navigate to="/login" state={{ next: location }} replace />
  }

  return children
}

export default function App() {
  const queryClient = new QueryClient()
  const [serviceWorkerUpdated, setServiceWorkerUpdated] = useState(false)

  const onWatchEventHandler = useCallback(({ data }) => {
    if (data && data.type === 'serviceworkerUpdate') {
      setServiceWorkerUpdated(true)
    }
  }, [])

  const handleUpdateClick = useCallback(() => window.location.reload(), [])

  const handleDismissClick = useCallback(
    () => setServiceWorkerUpdated(false),
    []
  )

  useEventListener('message', onWatchEventHandler)

  return (
    <>
      <Suspense fallback="">
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/logged-out" element={<LoggedOutPage />} />
            <Route path="/login/*" element={<LoginPage />} />
            <Route
              path="/*"
              element={
                <RequireAuth>
                  <MainPage />
                </RequireAuth>
              }
            />
          </Routes>
        </QueryClientProvider>

        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-20"
        >
          <Notification
            show={serviceWorkerUpdated}
            onCloseClick={handleDismissClick}
          >
            <SWNotificationContent
              onUpdateClick={handleUpdateClick}
              onDismissClick={handleDismissClick}
            />
          </Notification>
        </div>
      </Suspense>
    </>
  )
}
