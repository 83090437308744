import LoadingAnimation from 'components/loading-animation'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '@hookform/error-message'
import { updateAvailabilities } from 'services/api'
import { useMutation, useQueryClient } from 'react-query'

const useCalendarSchedule = () => {
  const {
    handleSubmit,
    submitting,
    register,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm()

  const queryClient = useQueryClient()

  const { mutateAsync, error } = useMutation(
    (values) => updateAvailabilities(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('availabilities')
      },
    }
  )

  const submit = (values) => {
    const submitValues = Object.keys(values).reduce((acc, day) => {
      return {
        ...acc,
        [day]: !!values[day].start ? values[day] : undefined,
      }
    }, {})

    return mutateAsync(submitValues)
  }

  return {
    onSubmit: handleSubmit(submit),
    submitting,
    register,
    getValues,
    errors,
    isSubmitting,
    error,
  }
}

export default function CalendarScheduleForm() {
  const { t } = useTranslation('calendar-settings')
  const { onSubmit, register, getValues, errors, isSubmitting } =
    useCalendarSchedule()
  const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  return (
    <form onSubmit={onSubmit}>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('scheduleTitle')}
            </h3>

            <p className="mt-1 text-sm text-gray-500">{t('scheduleDesc')}</p>
          </div>

          <div className="grid grid-cols-3 gap-6">
            {days.map((day) => (
              <div className="col-span-6 sm:col-span-3" key={day}>
                <label
                  htmlFor={day}
                  className="block text-sm font-medium text-gray-700"
                >
                  {t(day)}
                </label>

                <div className="md:flex">
                  <div className="mt-1 inline-block">
                    <input
                      {...register(`${day}.start`, {
                        pattern: {
                          value: /[0-9]{2}:[0-9]{2}/,
                          message: 'invalidTime',
                        },
                      })}
                      type="text"
                      id={`${day}_start`}
                      className="md:mr-4 w-full md:w-auto border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="ex : 09:00"
                    />

                    <ErrorMessage
                      errors={errors}
                      name={`${day}.start`}
                      render={({ message }) => (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {t(message)}
                        </p>
                      )}
                    />
                  </div>

                  <div className="mt-1 inline-block">
                    <input
                      {...register(`${day}.end`, {
                        pattern: {
                          value: /[0-9]{2}:[0-9]{2}/,
                          message: 'invalidTime',
                        },
                        validate: {
                          required: (value) =>
                            !!value ||
                            !getValues(`${day}.start`) ||
                            'requiredTime',
                        },
                      })}
                      type="text"
                      id={`${day}_end`}
                      className="border w-full md:w-auto border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="ex : 18:00"
                    />

                    <ErrorMessage
                      errors={errors}
                      name={`${day}.end`}
                      render={({ message }) => (
                        <p className="mt-2 text-sm text-red-600">
                          {t(message)}
                        </p>
                      )}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={isSubmitting}
          >
            {isSubmitting && <LoadingAnimation />}
            {t('saveButton')}
          </button>
        </div>
      </div>
    </form>
  )
}
