export class ApiError extends Error {
  constructor(message, { response, errorCode, statusCode, details }) {
    super(message)

    this.name = 'ApiError'
    this.response = response
    this.errorCode = errorCode
    this.statusCode = statusCode
    this.details = details
  }
}

export const parseResponse = async (response) => {
  if (!response.ok) {
    const json = await response.json()
    const error = new ApiError(json.error.message, {
      response: json,
      errorCode: json.error.code,
      statusCode: response.status,
      details: json.error.details,
    })

    throw error
  }

  if (response.status === 204) {
    return Promise.resolve(null)
  }

  return response.json()
}
