import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { setLogger } from 'react-query'
import { initializeApp } from 'firebase/app'
import moment from 'moment'
import 'moment/locale/fr'

import App from './App'
import * as serviceWorker from './serviceWorkerRegistration'

import './index.css'

import './i18n'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT

moment.locale('fr')

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

setLogger({
  log: (message) => {
    Sentry.captureMessage(message)
  },
  warn: (message) => {
    Sentry.captureMessage(message)
  },
  error: (error) => {
    Sentry.captureException(error)
  },
})

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    window.postMessage({ type: 'serviceworkerUpdate' })
  },
})
