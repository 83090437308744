import { Link } from 'react-router-dom'
import moment from 'moment'
import { useQuery } from 'react-query'
import { fetchReservationsList } from 'services/api'
import ReservationsListItem from './item'
import { useTranslation } from 'react-i18next'

const useReservationsList = () => {
  const page = 1
  const perPage = 5
  const now = moment().toISOString()
  const endsAtParam = `>${now}`
  const sortParam = '+starts_at,+external_id'

  const { data, isLoading } = useQuery(
    ['reservations', { perPage, sort: sortParam, page }],
    () =>
      fetchReservationsList({
        sort: sortParam,
        perPage,
        page,
        endsAt: endsAtParam,
        status: '!=pending',
      })
  )

  return {
    reservations: data?.reservations || [],
    isLoading,
  }
}

export default function ReservationsList() {
  const { t } = useTranslation('dashboard')
  const { reservations, isLoading } = useReservationsList()

  return (
    <div className="shadow sm:hidden">
      <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
        {reservations.map((reservation) => (
          <ReservationsListItem
            key={reservation.id}
            id={reservation.id}
            contactName={[
              reservation.contact.first_name,
              reservation.contact.last_name,
            ].join(' ')}
            service={[
              reservation.service_category_name,
              reservation.service_group_name,
              reservation.service_name,
            ].join(' / ')}
            city={reservation.contact.city}
            postalCode={reservation.contact.postcode}
            startsAt={reservation.starts_at}
            endsAt={reservation.ends_at}
            displayId={reservation.external_id}
            to={`/reservations/${reservation.id}`}
          />
        ))}

        {!isLoading && reservations.length === 0 && (
          <li>
            <div className="px-4 py-4 bg-white hover:bg-gray-50 text-center text-gray-500 text-sm">
              <div>{t('noUpcomingReservation')}</div>
              <div>
                <Link
                  className="font-medium text-cyan-700 hover:text-cyan-900"
                  to="/reservations"
                >
                  {t('seeAllReservations')}
                </Link>
              </div>
            </div>
          </li>
        )}
      </ul>

      {reservations.length > 0 && (
        <nav
          className="bg-white px-4 py-3 border-t border-gray-200"
          aria-label="Pagination"
        >
          <div className="text-center text-sm">
            <Link
              className="font-medium text-cyan-700 hover:text-cyan-900"
              to="/reservations"
            >
              {t('seeAllReservations')}
            </Link>
          </div>
        </nav>
      )}
    </div>
  )
}
