import DescriptionListField from 'components/description-list/description-list-field'
import Link from 'components/link'
import { useTranslation } from 'react-i18next'

export default function ReservationContact({
  contactName,
  address,
  address2,
  postalCode,
  city,
  phoneNumber,
  formattedPhoneNumber,
}) {
  const mapUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
    [address, postalCode, city].join(' ')
  )}`
  const { t } = useTranslation(['reservation-details'])

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t('Contact')}
        </h3>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <DescriptionListField label="Nom du contact">
            {contactName}
          </DescriptionListField>

          <DescriptionListField label="Adresse">
            <Link as="a" href={mapUrl} target="_blank">
              <div>{address}</div>
              <div>{address2}</div>
              <div>
                {postalCode} {city}
              </div>
            </Link>
          </DescriptionListField>

          <DescriptionListField label="N° de téléphone">
            <Link as="a" target="_blank" href={`tel:${phoneNumber}`}>
              {formattedPhoneNumber}
            </Link>
          </DescriptionListField>
        </dl>
      </div>
    </div>
  )
}
