import qs from 'query-string'

export default function getStripeOauthUrl({
  email,
  phoneNumber,
  firstName,
  lastName,
  businessType,
}) {
  const STRIPE_REDIRECT_URI = process.env.REACT_APP_STRIPE_REDIRECT_URI
  const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID

  return qs.stringifyUrl({
    url: 'https://dashboard.stripe.com/express/oauth/authorize',
    query: {
      response_type: 'code',
      client_id: STRIPE_CLIENT_ID,
      scope: 'read_write',
      redirect_uri: STRIPE_REDIRECT_URI,
      'stripe_user[email]': email,
      'stripe_user[country]': 'FR',
      'stripe_user[phone_number]': phoneNumber,
      'stripe_user[first_name]': firstName,
      'stripe_user[last_name]': lastName,
      'stripe_user[url]': 'https://simone.paris',
      'stripe_user[business_type]': businessType,
    },
  })
}
